import { 
  createRouter, 
  createWebHistory
} from 'vue-router'

const routes = [
  { path: '/', name: 'board', component: () => import('../views/DashboardView.vue') , meta:{ requiresAuth: true}},
  { path: '/:idWorkspace/home', name: 'home', component: () => import('../views/HomeView.vue') , meta:{ requiresAuth: true}},
  { path: '/:idWorkspace/chat', name: 'chat', component: () => import('../views/ChatView.vue') , meta:{ requiresAuth: true}},
  { path: '/:idWorkspace/inbox', name: 'inbox', component: () => import('../views/InboxView.vue') , meta:{ requiresAuth: true}},
  { path: '/:idWorkspace/team', name: 'team', component: () => import('../views/TeamView.vue') , meta:{ requiresAuth: true}},
  { 
    path: '/:idWorkspace/spaces', 
    // name: 'spaces', component: () => import('../views/space/SpaceView.vue'),
    children: [
      { path: '/:idWorkspace/project/list/:idProject', name: 'project_list', component: () => import('../views/space/ProjectListView.vue') },
      { path: '/:idWorkspace/project/calendar/:idProject', name: 'project_calendar', component: () => import('../views/space/ProjectCalendarView.vue') },
      { path: '/:idWorkspace/project/board/:idProject', name: 'project_board', component: () => import('../views/space/ProjectBoardView.vue') },
      { path: '/:idWorkspace/project/chat/:idProject/:idChatProjectGroup', name: 'project_chat', component: () => import('../views/space/ProjectChatView.vue') },
      { path: '/:idWorkspace/task/:idTask', name: 'task', component: () => import('../views/space/TaskView.vue')},
      { path: '/:idWorkspace/space/overview/:idSpace', name: 'space_overview', component: () => import('../views/space/SpaceOverView.vue')},
      ] 
    , meta:{ requiresAuth: true}
  },
  { path: '/login', name: 'login', component: () => import('../views/LoginView.vue'), meta:{ front_assets: true}},
  { path: '/hack_login', name: 'hack_login', component: () => import('../views/LoginHackView.vue'), meta:{ front_assets: true}},
  // { path: '/register', name: 'register', component: () => import('../views/RegisterView.vue'), meta:{ front_assets: true}},
  // { path: '/set_gd', name: 'set_gd', component: () => import('../views/set/SetGoogleDriveView.vue'), meta:{ front_assets: true}},
  ]

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes
})
import filters from '@/services/Filters';
const DEFAULT_TITLE = 'Start By Me | Get Jobs Done';
// document.title = DEFAULT_TITLE;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  // console.log(filters.checkAuth());
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (filters.checkAuth()) {
      if (to.query.plan != undefined) {
        window.history.pushState('', '', window.location.origin+to.path);
      }else{
        next();
      }
      return;
    }
    next("/login");
  } else {
    next();
  }
  // console.log(DEFAULT_TITLE);
});

export default router
