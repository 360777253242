<template>
  <div class="left" :style="{ minWidth: panelLeft + 'px' }">
    <div class="top-left">
      <div class="workspace-name" @click="actWorkspace($event)">
        <span class="one-alfabet green">O</span>
        <p>{{selectedWorkspace.name}}'s Workspace</p>
        <span class="om-chev-down"></span>
      </div>
      <div></div>
    </div>
    <div class="center-left">
      <div class="item">
        <div @click="routeApp('home')" :class="{ active: menu.name == 'home' }">
          <span class="om-home"></span>
          <p>Home</p>
        </div>
        <div @click="routeApp('inbox')"  :class="{ active: menu.name == 'inbox' }" class="menu-inbox">
          <span class="om-inbox"></span>
          <p>Updates</p>
          <span class="badge" v-if="totalNotif > 0">{{totalNotif}}</span>
        </div>
        <div @click="routeApp('chat')"  :class="{ active: menu.name == 'chat' }" class="menu-inbox">
          <span class="om-chat"></span>
          <p>Chat</p>
          <!-- <span class="badge" v-if="totalNotif > 0">{{totalNotif}}</span> -->
        </div>
        <!-- <div>
          <span class="om-document"></span>
          <p>Docs (soon)</p>
        </div>
        <div>
          <span class="om-bar-graph"></span>
          <p>Dashboard (soon)</p>
        </div>
        <div>
          <span class="om-video"></span>
          <p>Clips (soon)</p>
        </div>
        <div>
          <span class="om-timer"></span>
          <p>Timesheets (soon)</p>
        </div>
        <div>
          <span class="om-circle-more"></span>
          <p>More (soon)</p>
        </div> -->
      </div>
      <div class="workspace">
        <div class="space">
          <div class="name-space">
            <p>space</p>
          </div>
          <!-- <div class="item-space">
            <span class="om-home"></span>
            <p>Everything (soon)</p>
          </div> -->
          <div class="group-space" v-for="(item,key) in space" :key="key">
            <div class="group-name" @click="routeApp('space',item.id)" :class="{'active_group':idSpace == item.id}">
              <span class="one-alfabet green">{{$filters.firstCharacter(item.name)}}</span>
              <p>{{item.name}}</p>
              <div @click.stop="actSpace($event,item)" class="act_mini_btn" >
                <span class="om-more"></span>
              </div>
            </div>
            <div class="group-list">
              <div class="group-item" v-for="(item_child,key_child) in item.child" :key="key_child" @click="routeApp('project_list',item.id,item_child.id)" :class="{'active_group_item':idProject == item_child.id}">
                <span class="om-list"></span>
                <p>{{item_child.name}}</p>
                <div @click.stop="actProject($event,item_child)" class="act_mini_btn" >
                  <span class="om-more"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="item-space">
            <span class="om-grid"></span>
            <p>View All Spaces (soon)</p>
          </div> -->
          <div class="item-space" @click="openNewSpace()">
            <span class="om-circle-add"></span>
            <p>Create Space</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-left">
      <div class="act_mini_btn" @click="routeApp('team')">
        <span class="om-users"></span>
        <p>Invite</p>
      </div>
      <!-- <div>
        <span class="om-home"></span>
      </div> -->
    </div>
    <div class="dragHandleX" @mousedown="startDrag"></div>
  </div>
</template>
<script>
  import Service from '@/services/Services';
  import eventBus from '@/services/eventBus';
  export default {
    name: 'SideLeftView',
    props:{
      space:Array,
      workspace: Array,
      notification:Array
    },
    components: {
    },
    data: function() {
      return {
        panelLeft: 270,
        dragging: {
          isDragging: false,
          initialWidth:0,
          startX: 0, 
        },
        idWorkspace : 0,
        selectedWorkspace: {},
        idProject: '',
        idSpace: '',
        totalNotif: 0,
        dataTest: [{idSpace:11,idProject:111}],
        muNotification: this.notification,
        menu: {
          name: '',
          subName :'',
        }
      };
    },
    created: function() {
    },
    unmounted () {
    },
    methods: {
      routeApp(name = '', idSpace = '', idProject = ''){
        if(name == 'project_list') {
          this.setSpace({idWorkspace:this.idWorkspace,idSpace:idSpace,idProject:idProject});
          this.$router.push({name: 'project_list', params: {idWorkspace:this.idWorkspace,idSpace:idSpace,idProject:idProject}});
        }else if(name == 'space') {
          this.setSpace({idWorkspace:this.idWorkspace,idSpace:idSpace});
          this.$router.push({name: 'space_overview', params: {idWorkspace:this.idWorkspace,idSpace:idSpace}});
        }else{
          this.$router.push({name: name, params: {idWorkspace:this.idWorkspace}});
        }
        // console.log(name);
      },
      getStatus(){
        Service.post(this.$site_url+'space/get_status',{}).then((response) => {
          if(response.status == 'ok'){
            localStorage.setItem("dataStatus", JSON.stringify(response.data));
            // eventBus.$emit('project-task-get');
          }
        });
      },
      setSpace(dataParams){
        if (dataParams.idProject != null) {
          localStorage.setItem("idProject", dataParams.idProject);
        }else{
          localStorage.removeItem("idProject");
        }
        if (dataParams.idSpace != null) {
          localStorage.setItem("idSpace", dataParams.idSpace);
        }
        if (dataParams.idProject != null) {
          this.getStatus();
        }
      },
      openNewSpace(){
        eventBus.$emit('open-modal-space');
      },
      actSpace(el,params){
        eventBus.$emit('openDropdown', {target:el,
          component:'SpaceActComponent',
          data:params
        });
      },
      actProject(el,params){
        eventBus.$emit('openDropdown', {target:el,
          component:'ProjectActComponent',
          data:params
        });
      },
      actWorkspace(el){
        eventBus.$emit('openDropdown', {target:el,
          component:'WorkspaceActComponent',
          data:this.workspace
        });
      },
      startDrag(e){
        this.dragging.isDragging = true;
        this.dragging.startX = e.pageX;
        this.dragging.initialWidth = this.panelLeft;
        e.preventDefault(); // Mencegah seleksi teks
        document.addEventListener('mousemove', this.onDrag);
        document.addEventListener('mouseup', this.stopDrag); 
      },
      onDrag(e) {
        if (this.dragging.isDragging) {
          var currentX = e.pageX;
          this.panelLeft = this.dragging.initialWidth + (currentX - this.dragging.startX);
        }
      },
      stopDrag() {
        this.dragging.isDragging = false;
        document.removeEventListener('mousemove', this.onDrag);
        document.removeEventListener('mouseup', this.stopDrag);
      },
      updateNotifTotal(){
        this.totalNotif = 0;
        // console.log('berapa kali di hit',this.notification);
        if (this.notification != undefined) {
          this.notification.map((v)=>{
            this.totalNotif += parseInt(v.total);
          });
        }
      },
      checkWorkspace(){
        var getIdWorkspace = localStorage.getItem("idWorkspace");
        this.idWorkspace = getIdWorkspace;
        this.workspace.map((v)=>{
          if(v.id == getIdWorkspace){
            this.selectedWorkspace = v;
          }
        })
      },
      routeCheck(){
        var dataStatus = localStorage.getItem("dataStatus");
        if (dataStatus == null) {
          if (this.$route.params.idProject != undefined) {
            this.setSpace({idWorkspace:this.idWorkspace,idSpace:'',idProject:this.$route.params.idProject});
          }
        }
        if (this.$route.params.idProject != undefined) {
          this.idProject = this.$route.params.idProject;
        }else{
          this.idProject = '';
        }
        if (this.$route.params.idSpace != undefined) {
          this.idSpace = this.$route.params.idSpace;
        }else{
          this.idSpace = '';
        }
        this.menu.name = this.$route.name;
      }
    },
    mounted(){
      var thos = this;
      this.routeCheck();
      var dataSession = localStorage.getItem("OToken");
      if (dataSession != undefined || dataSession != null) {
        this.hasLogin = true;
      }
      this.updateNotifTotal();
      setTimeout(function() {
        thos.checkWorkspace();
      }, 100);

      eventBus.$on('update-status', function () {
        thos.getStatus();
      });
      eventBus.$on('select-route-left', function (name = '', idSpace = '', idProject = '') {
        thos.checkWorkspace();
        thos.routeApp(name,idSpace,idProject);
      });
      eventBus.$on('read-notif', function () {
        thos.totalNotif -= 1;
      });
      eventBus.$on('add-notif', function (data) {
        thos.totalNotif += 1;
        console.log(data);
      });

    },
    watch: {
      $route(){
        this.routeCheck();
      },
      'notification'(){
        this.updateNotifTotal();
      }
    }
  }
</script>
